import type { JSX } from 'react';

import { memo } from '@change-corgi/core/react/core';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconNavigateBefore, iconNavigateNext } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';

import type { FeaturedVictoryPetition } from 'src/app/pages/homepage/pageContext';

import { FeaturedVictoryCard } from './Card';

type CarouselSlideProps = {
	featuredVictories: readonly FeaturedVictoryPetition[];
	currentIndex: number;
	onCardSelect: (index: number) => void;
};

export const SimpleCarouselSlide = memo(function CarouselSlide({
	featuredVictories,
	currentIndex,
	onCardSelect,
}: CarouselSlideProps): JSX.Element | null {
	const onPrev = () => {
		onCardSelect(currentIndex <= 0 ? featuredVictories.length - 1 : currentIndex - 1);
	};
	const onNext = () => {
		onCardSelect(currentIndex >= featuredVictories.length - 1 ? 0 : currentIndex + 1);
	};

	return (
		<Box sx={{ position: 'relative' }} mb={16}>
			<FeaturedVictoryCard victory={featuredVictories[currentIndex]} />
			{/* using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */}
			<Box as="ul" role="list">
				<Box as="li">
					<Box
						sx={{
							position: 'absolute',
							left: -50,
							top: '50%',
							transform: 'translateY(-50%)',
							cursor: 'pointer',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'& button:active': {
								background: 'unset',
							},
						}}
						data-slide="prev"
					>
						{/* TODO: a11y i18n */}
						<button
							type="button"
							sx={{ ...buttonResetStyles }}
							aria-label="Previous Item"
							data-testid="carousel-prev-button"
							data-qa="carousel-prev-button"
							onClick={onPrev}
						>
							<Icon icon={iconNavigateBefore} size="xxxl" color="neutral-grey400" />
						</button>
					</Box>
				</Box>
				<Box as="li">
					<Box
						sx={{
							position: 'absolute',
							right: -50,
							top: '50%',
							transform: 'translateY(-50%)',
							cursor: 'pointer',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'& button:active': {
								background: 'unset',
							},
						}}
						data-slide="next"
					>
						{/* TODO: a11y i18n */}
						<button
							type="button"
							sx={{ ...buttonResetStyles }}
							aria-label="Next Item"
							data-testid="carousel-next-button"
							data-qa="carousel-next-button"
							onClick={onNext}
						>
							<Icon icon={iconNavigateNext} size="xxxl" color="neutral-grey400" />
						</button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
});
