import type { JSX } from 'react';

import { memo } from '@change-corgi/core/react/core';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Image } from '@change-corgi/design-system/components/media';
import { Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { derivedStyle } from '@change-corgi/design-system/theme';
import { Text } from '@change-corgi/design-system/typography';

import type { FeaturedVictoryPetition } from 'src/app/pages/homepage/pageContext';
import { DISPLAYED_VICTORIES } from 'src/app/pages/homepage/shared/consts';
import { DEFAULT_IMAGE } from 'src/app/shared/components/media';

function getMedia(victory: FeaturedVictoryPetition) {
	const defaultPhoto = { url: DEFAULT_IMAGE, processing: false };
	if (victory.photo) return victory.photo.petitionSmall;
	return defaultPhoto;
}

type CarouselThumbsProps = {
	featuredVictories: readonly FeaturedVictoryPetition[];
	currentIndex: number;
	onCardSelect: (index: number) => void;
};

export const SimpleCarouselThumbs = memo(function CarouselThumbs({
	featuredVictories,
	currentIndex,
	onCardSelect,
}: CarouselThumbsProps): JSX.Element | null {
	const handleSlideKeyDown = (e: React.KeyboardEvent<EventTarget>, index: number) => {
		if (e.key === 'Enter' || e.key === ' ') {
			e.preventDefault();
			onCardSelect(index);
		}
	};

	return (
		<Flex mx={-4}>
			{featuredVictories.map((victory, index) => (
				<RouterLink
					// using index as a key as the victory.id can be present in duplicate for some reason
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					to={`/p/${victory.slug}`}
					data-testid={`carousel-thumb-${index}`}
					tabIndex={0}
					sx={{
						...linkResetStyles,
						cursor: 'pointer',
						width: `calc((100% / ${DISPLAYED_VICTORIES}) - ${4 * 2}px)`,
						mx: 4,
					}}
					onClick={(e) => {
						e.preventDefault();
						onCardSelect(index);
					}}
					onKeyDown={(e) => handleSlideKeyDown(e, index)}
				>
					<Image
						data-testid="petition-victory-image"
						alt=""
						src={
							getMedia(victory).processing
								? 'https://static.change.org/images/default-petition-darker.gif'
								: getMedia(victory).url
						}
						aspectRatio="16/9"
						sx={{
							verticalAlign: 'middle',
						}}
					/>
					<Text
						mt={0}
						as="p"
						size="small"
						color={currentIndex === index ? 'primary-changeRed' : 'primary-black'}
						pt={currentIndex === index ? 1 : 5}
						sx={{
							borderTop: derivedStyle(({ colors }) =>
								currentIndex === index ? `4px solid ${colors['primary-changeRed']}` : 'none',
							),
						}}
					>
						{victory.displayTitle}
					</Text>
				</RouterLink>
			))}
		</Flex>
	);
});
