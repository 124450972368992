import { useI18n } from '@change-corgi/core/react/i18n';

import type { FeedItem } from '../api/types';

export type PetitionContent = {
	href: string;
	photo: FeedItem['node']['photo'];
	title: string;
	userPhoto?: string;
	username: string;
	supportersShort: string;
	supporters: string;
	description: string;
};

const getUserData = (
	feedItem: FeedItem,
): {
	userPhoto?: string;
	username: string;
} => {
	if (feedItem.node.organization) {
		return {
			userPhoto: feedItem.node.organization.photo?.organizationSmall.url,
			username: feedItem.node.organization.name,
		};
	}
	return {
		userPhoto: feedItem.node.user.photo?.userLarge.url,
		username: feedItem.node.user.displayName,
	};
};

export function usePetitionContent(feedItem: FeedItem): PetitionContent | undefined {
	const { translatePlural } = useI18n();

	switch (feedItem.__typename) {
		case 'RecommendedPetitionConnection':
		case 'FeaturedPetitionConnection': {
			const supporterCount = feedItem.node.signatureState.signatureCount.displayed || 0;
			const supporterCountLocalized = feedItem.node.signatureState.signatureCount.displayedLocalized || '0';

			return {
				href: `/p/${encodeURIComponent(feedItem.node.slug)}?source_location=discover_feed`,
				photo: feedItem.node.photo,
				title: feedItem.node.ask,
				...getUserData(feedItem),
				supporters: translatePlural('fe.pages.petition_show.num_supporters', supporterCount, {
					num: supporterCountLocalized,
				}),
				supportersShort: supporterCountLocalized,
				description: feedItem.node.description,
			};
		}
		case 'PromotedPetitionConnection': {
			const supporterCount = feedItem.node.signatureState.signatureCount.displayed || 0;
			const supporterCountLocalized = feedItem.node.signatureState.signatureCount.displayedLocalized || '0';

			return {
				href: `/p/${encodeURIComponent(feedItem.node.slug)}?pt=${encodeURIComponent(
					feedItem.promoteToken,
				)}&source_location=discover_feed&pt_algorithm=${encodeURIComponent(feedItem.algorithm)}`,
				photo: feedItem.node.photo,
				title: feedItem.node.ask,
				...getUserData(feedItem),
				supporters: translatePlural('fe.pages.petition_show.num_supporters', supporterCount, {
					num: supporterCountLocalized,
				}),
				supportersShort: supporterCountLocalized,
				description: feedItem.node.description,
			};
		}
		default: {
			return undefined;
		}
	}
}
