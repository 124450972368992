import { useCallback } from 'react';

import type { FeedItemFeaturedPetition, FeedItemRecommendedPetition } from '../../../api/types';
import type { PetitionContext } from '../types';

import { useMediaMention } from './useMediaMention';
import { useTrendingInMovement } from './useTrendingInMovement';
import { useTrendingInTag } from './useTrendingInTag';

export function useFeaturedPetitions(): (
	feedItem: FeedItemFeaturedPetition | FeedItemRecommendedPetition,
) => PetitionContext | undefined {
	const trendingInMovement = useTrendingInMovement();
	const trendingInTag = useTrendingInTag();
	const mediaMention = useMediaMention();

	return useCallback(
		// eslint-disable-next-line complexity
		(feedItem) => {
			if (feedItem.node.movements.nodes && feedItem.node.movements.nodes[0]) {
				const { objective, slug } = feedItem.node.movements.nodes[0];
				return trendingInMovement({ objective, slug });
			}
			if (feedItem.node.tagsConnection?.nodes && feedItem.node.tagsConnection.nodes[0]) {
				const { slug, name, id } = feedItem.node.tagsConnection.nodes[0];
				return trendingInTag({ slug, topic: name, tagId: id });
			}
			if (feedItem.node.petitionUpdatesConnection.nodes && feedItem.node.petitionUpdatesConnection.nodes[0]) {
				return mediaMention(feedItem);
			}
			return undefined;
		},
		[mediaMention, trendingInMovement, trendingInTag],
	);
}
